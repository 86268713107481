import { parseISO } from "date-fns";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Cart, OrderType } from "@efarmz/efarmz-domain-typescript";

const dateTimeReviver = (key: string, value: any) => {
  if (key === `createdAt`) {
    return parseISO(value);
  }
  if (key === `refreshedAt`) {
    return parseISO(value);
  }
  return value;
};

const defaultValues: Cart & {
  version: number;
} = {
  version: 1,
  meals: {
    orderType: OrderType.ONESHOT,
    items: [],
  },
  products: {
    items: [],
  },
  coupons: [],
  refunds: [],
  subTotal: 0,
  total: 0,
  deliveryFee: 0,
  totalCoupon: 0,
  totalRefund: 0,
};

const { persistAtom } = recoilPersist({
  key: `cart`,
  converter: {
    stringify: JSON.stringify,
    parse: (value) => {
      const { CartAtom, ...other } = JSON.parse(value, dateTimeReviver);
      if (!CartAtom?.version || CartAtom?.version !== defaultValues.version) {
        return {
          ...other,
          CartAtom: defaultValues,
        };
      }
      return JSON.parse(value, dateTimeReviver);
    },
  },
});

export const CartAtom = atom<
  Cart & {
    version: number;
  }
>({
  key: `CartAtom`,
  default: defaultValues,
  effects_UNSTABLE: [persistAtom],
});
