"use client";
import { useCallback, useEffect } from "react";

import { isBefore, isSameDay } from "date-fns";
import { useLocale } from "next-intl";
import { useRecoilState } from "recoil";

import {
  DeliveryWindow,
  OrderableItemType,
  User,
} from "@efarmz/efarmz-domain-typescript";

import useAuth from "@/hooks/useAuth";
import useDeliveryOptions from "@/hooks/useDeliveryOptions";

import { DeliveryWindowAtom } from "@/state/atoms/DeliveryWindowAtom";
import getDefaultDeliveryWindowByLocale from "@/utils/shared/getDefaultDeliveryWindowByLocale";
import getNextCarrierDeliveryDate from "@/utils/shared/getNextCarrierDeliveryDate";
import getNextCarrierDeliveryDates from "@/utils/shared/getNextCarrierDeliveryDates";
import getNextDeliveryDate from "@/utils/shared/getNextDeliveryDate";

type DeliveryWindowProviderProps = {
  children: React.ReactNode;
};

const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as any);
};

const DeliveryWindowProvider = ({ children }: DeliveryWindowProviderProps) => {
  const [state, setState] = useRecoilState(DeliveryWindowAtom);
  const { user: authUser, status } = useAuth();

  const locale = useLocale();

  const { deliveryOptions, isLoading } = useDeliveryOptions();

  const getDefaultUserDeliveryWindow = useCallback(
    (user: User) => {
      const lastDeliveryWindow = user.lastDeliveryWindow;

      const deliveryWindow: DeliveryWindow = {};
      if (lastDeliveryWindow?.deliveryType) {
        deliveryWindow.deliveryType = lastDeliveryWindow.deliveryType;
      }
      if (lastDeliveryWindow?.address) {
        deliveryWindow.address = lastDeliveryWindow.address;
      }

      if (lastDeliveryWindow?.slot) {
        deliveryWindow.slot = lastDeliveryWindow.slot;
      }
      if (lastDeliveryWindow?.carrier) {
        deliveryWindow.carrier = lastDeliveryWindow.carrier;
      }

      let deliveryDate: Date | null = null;
      if (deliveryWindow?.carrier?.id) {
        deliveryDate = getNextCarrierDeliveryDate(
          deliveryOptions,
          deliveryWindow.carrier
        );
      }

      return {
        ...deliveryWindow,
        date:
          deliveryDate ||
          getNextDeliveryDate({ type: OrderableItemType.PRODUCT }),
      };
    },
    [deliveryOptions]
  );

  const getDefaultDeliveryWindow = useCallback(() => {
    const deliveryWindow = getDefaultDeliveryWindowByLocale(locale);

    let shortDeliveryDate: Date | null = null;

    if (deliveryWindow?.carrier?.id) {
      shortDeliveryDate = getNextCarrierDeliveryDate(
        deliveryOptions,
        deliveryWindow.carrier
      );
    }

    return {
      ...deliveryWindow,
      date:
        shortDeliveryDate ||
        getNextDeliveryDate({ type: OrderableItemType.PRODUCT }),
    };
  }, [deliveryOptions, locale]);

  useEffect(() => {
    if (status !== `loading` && !isLoading) {
      let hasChanged = false;
      const defaultDeliveryWindow = getDefaultDeliveryWindow();

      let deliveryWindow: DeliveryWindow | null = null;

      if (authUser?.lastDeliveryWindow && !state.hasChanged) {
        //si il n'existe pas de dlw ou si la date est dépassée
        deliveryWindow = getDefaultUserDeliveryWindow(authUser);
        hasChanged = true;
      } else if (
        !state.value ||
        (state?.value?.date && !isValidDate(state.value.date)) ||
        (state?.value?.date &&
          isBefore(state.value.date, defaultDeliveryWindow.date) &&
          !isSameDay(state.value.date, defaultDeliveryWindow.date))
      ) {
        if (authUser?.lastDeliveryWindow) {
          deliveryWindow = getDefaultUserDeliveryWindow(authUser);
          hasChanged = true;
        } else {
          deliveryWindow = getDefaultDeliveryWindow();
        }
      }

      let longDeliveryDate: Date | null = getNextDeliveryDate({
        type: OrderableItemType.MEAL,
      });

      if (deliveryWindow !== null) {
        let carrierNextDeliveryDates: string[] = [];

        if (deliveryWindow?.carrier?.id) {
          longDeliveryDate = getNextCarrierDeliveryDate(
            deliveryOptions,
            deliveryWindow.carrier,
            OrderableItemType.MEAL
          );
          if (deliveryWindow.date) {
            carrierNextDeliveryDates = getNextCarrierDeliveryDates(
              deliveryOptions,
              deliveryWindow.carrier,
              deliveryWindow.date,
              2
            );
          }
        }

        setState((state) => {
          return {
            ...state,
            value: deliveryWindow,
            carrierNextDeliveryDates,
            hasChanged,
            longDeliveryDate: longDeliveryDate,
          };
        });
      }
    }
  }, [
    authUser,
    deliveryOptions,
    getDefaultDeliveryWindow,
    getDefaultUserDeliveryWindow,
    isLoading,
    locale,
    setState,
    state,
    status,
  ]);

  return <>{children}</>;
};

export default DeliveryWindowProvider;
