import { UpdateSession, useSession } from "next-auth/react";

import { User } from "@efarmz/efarmz-domain-typescript";

import formatJsonObjectDates from "@/utils/shared/formatJsonObjectDates";

const useAuth = (
  options?: any
): {
  user: User | null;
  status: `authenticated` | `loading` | `unauthenticated`;
  update: UpdateSession;
} => {
  const session = useSession();

  return {
    user: session?.data?.user
      ? (formatJsonObjectDates(session.data.user) as User)
      : null,
    status: session.status,
    update: session.update,
  };
};

export default useAuth;
