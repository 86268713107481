import { useCallback } from "react";

import { format, getWeek, getYear, isBefore, isSameDay } from "date-fns";
import { useRecoilState } from "recoil";

import {
  OrderableItemType,
  OrderItemStatus,
} from "@efarmz/efarmz-domain-typescript";

import useDeliveryWindow from "@/hooks/useDeliveryWindow";

import { CartAtom } from "@/state/atoms/CartAtom";
import getNextDeliveryDate from "@/utils/shared/getNextDeliveryDate";

const useUpdateCartAvailability = (): ((
  type: `all` | `meals` | `products`
) => void) => {
  const [value, setValue] = useRecoilState(CartAtom);
  const deliveryWindow = useDeliveryWindow();

  const updateCartAvailability = useCallback(
    (type: `all` | `meals` | `products`) => {
      const availableDeliveryDates = [deliveryWindow!.value!.date!];

      //always compare 2 dates for late delivery
      if (deliveryWindow.longDeliveryDate) {
        availableDeliveryDates.push(deliveryWindow.longDeliveryDate);
      }

      if (availableDeliveryDates?.length !== 2) {
        return;
      }

      const deliveryWeeks = [
        `${getYear(availableDeliveryDates[0])}${getWeek(
          availableDeliveryDates[0]
        ).toLocaleString(`en-US`, {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`,
        `${getYear(availableDeliveryDates[1])}${getWeek(
          availableDeliveryDates[1]
        ).toLocaleString(`en-US`, {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`,
      ];

      setValue((v) => {
        const unavailableMealIds: Record<string, OrderItemStatus> = {};
        const unavailableProductIds: Record<string, OrderItemStatus> = {};

        let updatedMealItems = v.meals.items || [];
        if (type === `all` || type === `meals`) {
          value?.meals?.items?.forEach((orderItem) => {
            const nextDeliveryDate = getNextDeliveryDate(orderItem.item);

            if (
              (isBefore(availableDeliveryDates[1], nextDeliveryDate) &&
                !isSameDay(availableDeliveryDates[1], nextDeliveryDate)) ||
              !orderItem?.item?.parent?.availableWeeks?.includes(
                deliveryWeeks[1]
              )
            ) {
              unavailableMealIds[orderItem.item.id!] =
                OrderItemStatus.UNAVAILABLE;
            }
          });

          updatedMealItems = v.meals.items.map((orderItem) => ({
            ...orderItem,
            status: Object.prototype.hasOwnProperty.call(
              unavailableMealIds,
              orderItem.item!.id!
            )
              ? unavailableMealIds[orderItem.item!.id!]
              : OrderItemStatus.AVAILABLE,
          }));
        }
        let updatedProductItems = v.products.items || [];
        if (type === `all` || type === `products`) {
          const firstBasketDeliveryDate = getNextDeliveryDate({
            type: OrderableItemType.BASKET,
          });

          value?.products?.items?.forEach((orderItem) => {
            const hasSomeStock = orderItem?.item?.stock
              ? Object.keys(orderItem!.item!.stock).some((key) => {
                  return orderItem!.item!.stock![key] > 0;
                })
              : false;

            const dayKey = format(availableDeliveryDates[0], `yyyyMMdd`);
            if (
              orderItem?.item?.type === OrderableItemType.PRODUCT &&
              orderItem?.item?.stock &&
              orderItem?.item?.stock[dayKey] < 1
            ) {
              if (hasSomeStock) {
                unavailableProductIds[orderItem.item.id!] =
                  OrderItemStatus.UNAVAILABLE;
              } else {
                unavailableProductIds[orderItem.item.id!] =
                  OrderItemStatus.OUT_OF_STOCK;
              }
            }

            if (orderItem?.item?.offDays?.includes(dayKey)) {
              unavailableProductIds[orderItem.item.id!] =
                OrderItemStatus.BRAND_ON_VACATION;
            }
            if (
              orderItem?.item?.type === OrderableItemType.BASKET &&
              isBefore(availableDeliveryDates[0], firstBasketDeliveryDate) &&
              !isSameDay(availableDeliveryDates[0], firstBasketDeliveryDate) &&
              isBefore(availableDeliveryDates[1], firstBasketDeliveryDate) &&
              !isSameDay(availableDeliveryDates[1], firstBasketDeliveryDate)
            ) {
              unavailableProductIds[orderItem.item.id!] =
                OrderItemStatus.UNAVAILABLE;
            }

            if (orderItem.item && !orderItem?.item?.isOnline) {
              unavailableProductIds[orderItem.item.id!] =
                OrderItemStatus.UNAVAILABLE;
            }

            updatedProductItems = v.products.items
              ?.filter((orderItem) => !!orderItem?.item?.id)
              .map((orderItem) => ({
                ...orderItem,
                status: Object.prototype.hasOwnProperty.call(
                  unavailableProductIds,
                  orderItem.item!.id!
                )
                  ? unavailableProductIds[orderItem.item!.id!]
                  : OrderItemStatus.AVAILABLE,
              }));
          });
        }

        return {
          ...v,
          meals: {
            ...v.meals,
            items: updatedMealItems,
          },
          products: {
            ...v.products,
            items: updatedProductItems,
          },
        };
      });
    },
    [deliveryWindow, setValue, value?.meals?.items, value?.products?.items]
  );

  return updateCartAvailability;
};

export default useUpdateCartAvailability;
