import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/(website)/[locale]/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--montserrat-font\",\"display\":\"fallback\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next/font/local/target.css?{\"path\":\"src/app/(website)/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../styles/fonts/TjardaHand-BoldItalic.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--tjardaHand-font\"}],\"variableName\":\"tjardaHand\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/app/(website)/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/swiper/modules/pagination.css");
