"use client";

import { useEffect } from "react";

import { differenceInSeconds, isSameDay } from "date-fns";
import { useRecoilState } from "recoil";

import useRefreshCart from "@/hooks/carts/useRefreshCart";
import useUpdateCartAvailability from "@/hooks/carts/useUpdateCartAvailability";
import useDeliveryWindow from "@/hooks/useDeliveryWindow";
import usePrevious from "@/hooks/usePrevious";

import { CartAtom } from "@/state/atoms/CartAtom";
import getMealCurrentWeeks from "@/utils/shared/getMealCurrentWeeks";

type CartProviderProps = {
  children: React.ReactNode;
};

const CartProvider = ({ children }: CartProviderProps) => {
  const [value, setValue] = useRecoilState(CartAtom);
  const refreshCart = useRefreshCart();
  const updateCartAvailability = useUpdateCartAvailability();
  const deliveryWindow = useDeliveryWindow();

  const previousDeliveryDate = usePrevious(deliveryWindow?.value?.date);
  const previousCarrierId = usePrevious(deliveryWindow?.value?.carrier?.id);

  const previousMealOrderType = usePrevious(value.meals.orderType);

  //on suprrime les plats qui ne sont plus dispo les 3 prochaines semaines
  useEffect(() => {
    const periods = getMealCurrentWeeks();

    const updatedItems = value.meals.items.filter((orderItem) => {
      return orderItem?.item?.availableWeeks?.some((week) =>
        periods.includes(week)
      );
    });
    setValue((state) => {
      return {
        ...state,
        meals: {
          ...state.meals,
          items: updatedItems,
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //actualisation des dispos si changement de type de commande plats (abo|oneshot)
  useEffect(() => {
    if (previousMealOrderType !== value.meals.orderType) {
      updateCartAvailability(`meals`);
      // alert(`dsfd`);
    }
  }, [previousMealOrderType, updateCartAvailability, value.meals.orderType]);

  //actualisation du cart après 200s
  useEffect(() => {
    if (
      !value?.refreshedAt ||
      (value?.refreshedAt &&
        differenceInSeconds(new Date(), value?.refreshedAt) > 200)
    ) {
      refreshCart().then(() => updateCartAvailability(`all`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.refreshedAt]);

  //actualisation du cart si changement de date de livraison ou changement de carrier
  useEffect(() => {
    if (
      deliveryWindow.value.date &&
      previousDeliveryDate &&
      deliveryWindow.value.carrier?.id &&
      (!isSameDay(deliveryWindow.value.date, previousDeliveryDate) ||
        (deliveryWindow.value.carrier?.id &&
          deliveryWindow.value.carrier?.id !== previousCarrierId))
    ) {
      updateCartAvailability(`all`);
    }
  }, [
    deliveryWindow,
    deliveryWindow.value.date,
    previousCarrierId,
    previousDeliveryDate,
    setValue,
    updateCartAvailability,
    value.meals.items,
    value.products.items,
  ]);

  return <>{children}</>;
};

export default CartProvider;
