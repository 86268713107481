import { useCallback } from "react";

import { isBefore, isSameDay } from "date-fns";
import { useLocale } from "next-intl";
import { useRecoilState } from "recoil";

import {
  DeliveryWindow,
  OrderableItemType,
} from "@efarmz/efarmz-domain-typescript";

import useAuth from "./useAuth";

import {
  DeliveryWindowAtom,
  DeliveryWindowAtomType,
} from "@/state/atoms/DeliveryWindowAtom";
import getDefaultDeliveryWindowByLocale from "@/utils/shared/getDefaultDeliveryWindowByLocale";
import getNextCarrierDeliveryDate from "@/utils/shared/getNextCarrierDeliveryDate";
import getNextCarrierDeliveryDates from "@/utils/shared/getNextCarrierDeliveryDates";

const useDeliveryWindow = (): DeliveryWindowAtomType & {
  isFormOpen: boolean;
  value: DeliveryWindow;
  setValue: (newValue: DeliveryWindow, deliveryOptions: any) => void;
  openForm: () => void;
  closeForm: () => void;
} => {
  const locale = useLocale();
  const [state, setState] = useRecoilState(DeliveryWindowAtom);
  const { user } = useAuth();

  const setValue = useCallback(
    (newValue: DeliveryWindow, deliveryOptions: any) => {
      let carrierNextDeliveryDates: string[] = [];

      let longDeliveryDate: Date = newValue.date || new Date();

      if (newValue?.carrier?.id && deliveryOptions && newValue.date) {
        carrierNextDeliveryDates = getNextCarrierDeliveryDates(
          deliveryOptions,
          { id: newValue?.carrier?.id },
          newValue.date,
          2
        );

        const nextLongDeliveryDate =
          getNextCarrierDeliveryDate(
            deliveryOptions,
            newValue.carrier,
            OrderableItemType.MEAL
          ) || new Date();

        if (
          isBefore(newValue.date, nextLongDeliveryDate) &&
          !isSameDay(newValue.date, nextLongDeliveryDate)
        ) {
          longDeliveryDate = nextLongDeliveryDate;
        }
      }
      let newComputedValue = newValue;
      if (user?.lastDeliveryWindow?.carrier?.id === newValue?.carrier?.id) {
        newComputedValue = {
          ...user?.lastDeliveryWindow,
          id: undefined,
          date: newValue.date,
        };
      }

      setState((s) => ({
        ...s,
        value: {
          ...s.value,
          ...newComputedValue,
        },
        hasChanged: true,
        carrierNextDeliveryDates,
        longDeliveryDate,
      }));
    },
    [setState, user?.lastDeliveryWindow]
  );

  const handleOpenForm = () => {
    setState((s) => ({ ...s, isFormOpen: true }));
  };

  const handleCloseForm = () => {
    setState((s) => ({ ...s, isFormOpen: false }));
  };

  return {
    ...state,
    value: state?.value
      ? state.value
      : getDefaultDeliveryWindowByLocale(locale),
    setValue,
    openForm: handleOpenForm,
    closeForm: handleCloseForm,
  };
};

export default useDeliveryWindow;
