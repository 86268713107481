import useSWR from "swr";

import useSubscription from "@/hooks/subscriptions/useSubscription";

import SWRFetcher from "@/utils/SWRFetcher";

const useSubscriptionOffs = () => {
  const subscription = useSubscription();

  const { data } = useSWR(
    subscription?.id
      ? { url: `/api/subscriptions/${subscription.id}/offs` }
      : null,
    SWRFetcher
  );

  return data?.data ? data.data : null;
};

export default useSubscriptionOffs;
