import { useEffect, useState } from "react";

import { useSearchParams } from "next/navigation";

import Feature from "@/types/Feature";

const useSetFeatureFlagFromQueryString = () => {
  const [isInit, setIsInit] = useState(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!isInit) {
      searchParams.forEach((value, key) => {
        if (Object.keys(Feature).includes(key)) {
          if (value?.toLocaleLowerCase() === `on`) {
            localStorage.setItem(key, `ON`);
          } else if (value?.toLocaleLowerCase() === `off`) {
            localStorage.removeItem(key);
          }
        }
      });
      setIsInit(true);
    }
  }, [isInit, searchParams]);
};

export default useSetFeatureFlagFromQueryString;
