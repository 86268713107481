import { format, getDay } from "date-fns";

import { config, OrderableItem } from "@efarmz/efarmz-domain-typescript";

const getNextDayOfWeek = (date: Date, dayOfWeek: number) => {
  var resultDate = new Date(date.getTime());

  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));

  return resultDate;
};

const getNextDeliveryDate = (item: OrderableItem) => {
  const currentDay = getDay(new Date());

  const cutoffs: Record<string, Record<string, string[]>> = config.cutoffs
    .default;

  if (item.type && cutoffs[`D${currentDay}`][item.type]) {
    const targetTime = cutoffs[`D${currentDay}`][item.type][1];
    const nextDayIndex = currentDay === 6 ? 0 : currentDay + 1;
    const currentTime = format(new Date(), `HH:mm`);

    const targetDayIndex =
      currentTime >= targetTime && currentTime <= `23:59`
        ? nextDayIndex
        : currentDay;

    const targetDay = cutoffs[`D${targetDayIndex}`][item.type][0];

    const targetDayNumber = parseInt(targetDay.replace(`D`, ``));

    const nextTargetDate = getNextDayOfWeek(new Date(), targetDayNumber);
    nextTargetDate.setHours(0, 0, 0);

    return nextTargetDate;
  }

  return new Date();
};

export default getNextDeliveryDate;
