import {
  DeliveryType,
  DeliveryWindow,
  OrderableItemType,
} from "@efarmz/efarmz-domain-typescript";

import getNextDeliveryDate from "./getNextDeliveryDate";

const getDefaultDeliveryWindowByLocale = (locale: string): DeliveryWindow => {
  const carrier = {
    id: locale === `fr` ? 2786 : 2786,
  };

  const deliveryWindow: DeliveryWindow = {
    date: getNextDeliveryDate({ type: OrderableItemType.PRODUCT }),
    carrier,
    deliveryType: DeliveryType.HOME,
    address: {
      zipcode: locale === `fr` ? `1000` : `1000`,
      countryIso2: `BE`,
    },
  };

  return { ...deliveryWindow };
};

export default getDefaultDeliveryWindowByLocale;
