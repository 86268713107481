import {
  Meal,
  OrderableItem,
  OrderableItemType,
  Product,
} from "@efarmz/efarmz-domain-typescript";

import SegmentProduct from "./types/SegmentProduct";

const formatOrderableItem = (orderableItem: OrderableItem): SegmentProduct => {
  const segmentProduct: SegmentProduct = {
    currency: `EUR`,
  };

  if (orderableItem?.id) {
    if (orderableItem?.type === OrderableItemType.MEAL) {
      const meal: Meal = orderableItem as Meal;
      segmentProduct.product_id = meal?.parent?.id
        ? meal.parent.id
        : orderableItem.id;
      if (meal?.parent?.id) {
        segmentProduct.variant = meal.id;
      }
    } else {
      segmentProduct.product_id = orderableItem?.id;
      if (
        orderableItem?.type === OrderableItemType.PRODUCT &&
        (orderableItem as Product)?.conditioning
      ) {
        segmentProduct.variant = (orderableItem as Product).conditioning;
      }
    }
  }

  if (orderableItem?.name) {
    segmentProduct.name = orderableItem?.name;
  }
  if (orderableItem?.image) {
    segmentProduct.image_url = `https://cdn.efarmz.be/cdn-cgi/image/quality=75,fit=cover,w=500,h=350/https://cdn.efarmz.be/art/${orderableItem?.image}`;
  }
  if (orderableItem?.finalPrice) {
    segmentProduct.price = orderableItem?.finalPrice;
  }
  if (orderableItem?.brand?.name) {
    segmentProduct.brand = orderableItem.brand.name;
  }
  if (orderableItem?.categories && orderableItem.categories.length > 0) {
    orderableItem.categories.forEach((category, i) => {
      const key: string = `category${i === 0 ? `` : `_${i}`}`;

      // @ts-ignore
      segmentProduct[key] = category.name;
    });
    segmentProduct.price = orderableItem?.finalPrice;
  }
  return segmentProduct;
};

export default formatOrderableItem;
