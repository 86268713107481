import { useCallback } from "react";

import axios from "axios";
import { useLocale } from "next-intl";
import { useRecoilState } from "recoil";

import { CartAtom } from "@/state/atoms/CartAtom";

const useRefreshCart = (): (() => Promise<void>) => {
  const [value, setValue] = useRecoilState(CartAtom);
  const locale = useLocale();

  const refreshCart = useCallback(async () => {
    await axios
      .post(`/api/cart/refresh`, {
        cart: value,
        locale: locale?.toLocaleUpperCase(),
      })
      .then((response) => {
        setValue((state) => ({
          ...state,
          ...response.data.data,
          refreshedAt: new Date(),
        }));
      })
      .catch((error) => {
        console.error(error);
        setValue((state) => ({
          ...state,
          refreshedAt: new Date(),
        }));
      });
  }, [locale, setValue, value]);

  return refreshCart;
};

export default useRefreshCart;
