import { format, isAfter, isSameDay, parseISO } from "date-fns";

import { Carrier } from "@efarmz/efarmz-domain-typescript";

const getNextCarrierDeliveryDates = (
  deliveryOptions: any,
  carrier: Carrier,
  baseDate: Date,
  size: number,
  slot?: string
) => {
  if (!carrier?.id || !baseDate || !deliveryOptions) {
    return [];
  }

  const carrierByIds: any = {};

  deliveryOptions.carriers?.forEach((item: any) => {
    carrierByIds[item.carrier_id] = item;
  });

  deliveryOptions.markers?.forEach((item: any) => {
    carrierByIds[item.carrier_id] = item;
  });

  const carrierFound = carrierByIds[carrier.id];

  if (!carrierFound) {
    return [];
  }

  const carrierNextDeliveryDates = carrierFound.availabilities
    ?.filter((innerAvailabilitiy: any) => {
      try {
        const innerStart = parseISO(innerAvailabilitiy.start);
        const innerEnd = parseISO(innerAvailabilitiy.end);

        const innerSlot = `${format(innerStart, `HH:mm`)} - ${format(
          innerEnd,
          `HH:mm`
        )}`;

        if (slot) {
          return (
            isAfter(innerStart, baseDate) &&
            innerSlot === slot &&
            !isSameDay(innerStart, baseDate)
          );
        }

        return (
          isAfter(innerStart, baseDate) && !isSameDay(innerStart, baseDate)
        );
      } catch (e) {
        return false;
      }
    })
    .sort((a: any, b: any) => {
      const startA = parseISO(a.start);
      const startB = parseISO(b.start);
      if (startA < startB) {
        return -1;
      } else if (startA == startB) {
        return 0;
      } else {
        return 1;
      }
    })
    .map((innerAvailabilitiy: any) => {
      const innerStart = parseISO(innerAvailabilitiy.start);
      return format(innerStart, `dd/MM/yyyy`);
    });

  return carrierNextDeliveryDates.slice(0, size);
};

export default getNextCarrierDeliveryDates;
