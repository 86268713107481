import useSWR from "swr";

import { OrderItem, Subscription } from "@efarmz/efarmz-domain-typescript";

import useAuth from "../useAuth";

import SWRFetcher from "@/utils/SWRFetcher";

const useSubscription = ():
  | (Subscription & {
      mealBox?: OrderItem;
    })
  | null => {
  const { user } = useAuth();

  const { data } = useSWR(
    user?.subscription?.id
      ? {
          url: `/api/subscriptions/${user!.subscription!.id}`,
        }
      : null,
    SWRFetcher,
    {
      fallbackData: {
        status: `success`,
        data: user?.subscription,
      },
    }
  );

  const subscription: Subscription = data?.data ? data.data : null;

  let mealBox: OrderItem | undefined = undefined;

  if (subscription && subscription?.items?.length) {
    mealBox =
      subscription.items.find((orderItem) =>
        orderItem?.item?.id?.startsWith(`BOX`)
      ) || undefined;
  }

  return subscription ? { ...subscription, mealBox } : null;
};

export default useSubscription;
