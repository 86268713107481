import { parseISO } from "date-fns";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { DeliveryWindow } from "@efarmz/efarmz-domain-typescript";

export type DeliveryWindowAtomType = {
  isFormOpen: boolean;
  hasChanged: boolean;
  version: number;
  value: DeliveryWindow | null;
  carrierNextDeliveryDates: string[];
  longDeliveryDate: Date | null;
};

const defaultValues: DeliveryWindowAtomType = {
  version: 1,
  isFormOpen: false,
  hasChanged: false,
  value: null,
  carrierNextDeliveryDates: [],
  longDeliveryDate: null,
};

const dateTimeReviver = (key: string, value: any) => {
  if (key === `date`) {
    return parseISO(value);
  }
  if (key === `createdAt`) {
    return parseISO(value);
  }
  if (key === `longDeliveryDate`) {
    return parseISO(value);
  }

  return value;
};

const { persistAtom } = recoilPersist({
  key: `deliveryWindow`,
  converter: {
    stringify: JSON.stringify,
    parse: (value) => {
      const { DeliveryWindowAtom, ...other } = JSON.parse(
        value,
        dateTimeReviver
      );

      if (
        !DeliveryWindowAtom?.version ||
        DeliveryWindowAtom?.version !== defaultValues.version
      ) {
        return {
          ...other,
          DeliveryWindowAtom: defaultValues,
        };
      }
      return JSON.parse(value, dateTimeReviver);
    },
  },
});

export const DeliveryWindowAtom = atom<DeliveryWindowAtomType>({
  key: `DeliveryWindowAtom`,
  default: defaultValues,
  effects_UNSTABLE: [persistAtom],
});
