import { useCallback, useContext } from "react";

import { EventProperties, SegmentEvent } from "@segment/analytics-next";

import AnalyticsContext from "@/contexts/AnalyticsContext";

import pckg from "../../package.json";
import useAuth from "./useAuth";

import segment from "@/utils/client/segment";

const useSegment = (): {
  page: (args: { name: string }) => void;
  track: (
    eventName: string | SegmentEvent,
    properties?: EventProperties | undefined
  ) => void;
} => {
  const { sessionId } = useContext(AnalyticsContext);

  const { user } = useAuth();

  const page = useCallback(
    (args: { name: string }) => {
      if (process?.env?.NEXT_PUBLIC_ENV_PROFILE === `production`) {
        segment?.page(
          undefined,
          args?.name,
          {
            userId: user?.id ? `${user.id}` : undefined,
            websiteVersion: pckg.version,
          },
          {
            userId: user?.id ? `${user.id}` : undefined,
            context: {
              sessionId,
              traits: {
                firstName: user?.firstname,
                lastname: user?.lastname,
                email: user?.email,
              },
              websiteVersion: pckg.version,
            },
          }
        );
      }
    },
    [sessionId, user?.email, user?.firstname, user?.id, user?.lastname]
  );

  const track = useCallback(
    (
      eventName: string | SegmentEvent,
      properties?: EventProperties | undefined
    ) => {
      if (process?.env?.NEXT_PUBLIC_ENV_PROFILE === `production`) {
        segment?.track(
          eventName,
          {
            ...properties,
            userId: user?.id ? `${user.id}` : undefined,
            websiteVersion: pckg.version,
          },
          {
            userId: user?.id ? `${user.id}` : undefined,
            context: {
              sessionId,
              traits: {
                firstName: user?.firstname,
                lastname: user?.lastname,
                email: user?.email,
              },
              websiteVersion: pckg.version,
            },
          }
        );
      } else {
        console.debug(eventName, properties);
      }
    },
    [sessionId, user?.email, user?.firstname, user?.id, user?.lastname]
  );

  return {
    page,
    track,
  };
};

export default useSegment;
