import React from "react";
export default function usePrevious<T>(value: T): T {
  // Create a reference to hold the previous version of the value,
  // as it is basically a generic object whose `current` property can hold any value.
  const ref = React.useRef<T>();
  // Use the `useEffect` hook to run a callback...
  React.useEffect(() => {
    // ...to store the passed value on the ref's current property...
    ref.current = value;
  }, [value]); // ...whenever the value changes.
  // And return the currently stored value,
  // as this will run before the `useEffect` callback runs.
  return ref.current as any;
}
