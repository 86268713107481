import formatJsonObjectDates from "./shared/formatJsonObjectDates";

//@ts-ignore
const SWRFetcher = (args) =>
  args === null
    ? Promise.resolve(null)
    : fetch(args.url, args.opts)
        .then((res) => res.json())
        .then((res) => formatJsonObjectDates(res) as any);

export default SWRFetcher;
