import useSWR from "swr";

import { Meal, OrderItem } from "@efarmz/efarmz-domain-typescript";

import useSubscription from "@/hooks/subscriptions/useSubscription";

import SWRFetcher from "@/utils/SWRFetcher";

const useSubscriptionSelectedMeals = () => {
  const subscription = useSubscription();

  const { data, isLoading } = useSWR(
    subscription?.mealBox
      ? { url: `/api/subscriptions/${subscription?.id}/selected-meals` }
      : null,
    SWRFetcher
  );

  if (isLoading) {
    return undefined;
  }

  return data?.data
    ? (data.data as Record<
        string,
        (OrderItem & {
          item: Meal;
          isDefault?: boolean;
        })[]
      >)
    : null;
};

export default useSubscriptionSelectedMeals;
