import useSWR from "swr";

import SWRFetcher from "@/utils/SWRFetcher";

const useDeliveryOptions = () => {
  const { data, error, isLoading, mutate } = useSWR(
    { url: `/api/dlw` },
    SWRFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const deliveryOptions = data?.data;

  return {
    deliveryOptions,
    error,
    isLoading,
    mutate,
  };
};

export default useDeliveryOptions;
