"use client";

import React, { useCallback, useEffect, useState } from "react";

import axios, { CancelTokenSource } from "axios";
import { useSWRConfig } from "swr";
import { useDebounce } from "use-debounce";

import {
  Meal,
  OrderItem,
  SubscriptionStatus,
} from "@efarmz/efarmz-domain-typescript";

import useSubscription from "@/hooks/subscriptions/useSubscription";
import useSubscriptionOffs from "@/hooks/subscriptions/useSubscriptionOffs";
import useSubscriptionSelectedMeals from "@/hooks/subscriptions/useSubscriptionSelectedMeals";
import usePrevious from "@/hooks/usePrevious";
import useSegment from "@/hooks/useSegment";

import formatOrderItem from "@/utils/shared/segment/formatOrderItem";
import SegmentProduct from "@/utils/shared/segment/types/SegmentProduct";

let offsSource: CancelTokenSource | null = null;
let mealsSource: CancelTokenSource | null = null;

type SubscriptionProviderProps = {
  children: React.ReactNode;
};

const flatOrderedIems = (orderItems: OrderItem[]): OrderItem[] => {
  return orderItems.map((orderItem) => {
    return {
      ...orderItem,
      item: {
        id: orderItem!.id! as any,
      },
    };
  });
};

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const subscription = useSubscription();
  const selectedMeals = useSubscriptionSelectedMeals();

  const segment = useSegment();

  const [isInit, setIsInit] = useState(false);

  const [subscriptionDebounced] = useDebounce(subscription, 1000);
  const [selectedMealsDebounced] = useDebounce(selectedMeals, 1000);

  const subscriptionDebouncedPrevious = usePrevious(subscriptionDebounced);
  const selectedMealsDebouncedPrevious = usePrevious(selectedMealsDebounced);
  const mealBoxPrevious = usePrevious(subscription?.mealBox);

  //offs
  const offs = useSubscriptionOffs();
  const [offsDebounced] = useDebounce(offs, 1000);
  const offsDebouncedPrevious = usePrevious(offsDebounced);

  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (!isInit && subscription) {
      setIsInit(true);
      mutate({
        url: `/api/subscriptions/${subscription?.id}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addServerOrderableItems = useCallback(
    async (items: OrderItem[]) => {
      if (subscription) {
        const products: SegmentProduct[] = [];
        let total = 0;

        items.forEach((orderItem) => {
          products.push(formatOrderItem(orderItem));
          total = (orderItem?.qty || 1) * (orderItem?.item?.finalPrice || 0);
        });

        segment.track(`SubscriptionItemsAdded`, {
          products,
          currency: `EUR`,
          value: total,
        });

        await axios
          .post(`/api/subscriptions/${subscription?.id}/items`, {
            orderItems: items,
          })
          .then(() => {
            const boxItem = subscription.items?.find((orderItem) =>
              orderItem?.item?.id?.startsWith(`BOX`)
            );
            if (boxItem) {
              mutate({
                url: `/api/subscriptions/${boxItem?.item?.id}/meals`,
              });
            }
          });
      }
    },
    [mutate, subscription]
  );

  const addServerAdditionalOrderableItems = useCallback(
    async (items: OrderItem[]) => {
      if (subscription) {
        await axios.post(
          `/api/subscriptions/${subscription?.id}/additionnals`,
          {
            orderItems: items,
          }
        );
      }
    },
    [subscription]
  );

  const updateServerStatus = useCallback(
    async (status: SubscriptionStatus) => {
      if (subscription) {
        await axios.post(`/api/subscriptions/${subscription?.id}/status`, {
          status,
        });
      }
    },
    [subscription]
  );

  useEffect(() => {
    //status changed
    if (
      subscriptionDebounced?.status &&
      subscriptionDebouncedPrevious?.status &&
      JSON.stringify(subscriptionDebounced.status) !==
        JSON.stringify(subscriptionDebouncedPrevious.status)
    ) {
      updateServerStatus(subscriptionDebounced.status);
    }

    //items
    if (
      subscriptionDebounced?.items &&
      subscriptionDebouncedPrevious?.items &&
      JSON.stringify(flatOrderedIems(subscriptionDebounced.items)) !==
        JSON.stringify(flatOrderedIems(subscriptionDebouncedPrevious.items))
    ) {
      addServerOrderableItems(subscriptionDebounced.items);
    }

    //additionnals items
    if (
      subscriptionDebounced?.additionnalItems &&
      subscriptionDebouncedPrevious?.additionnalItems &&
      JSON.stringify(
        flatOrderedIems(subscriptionDebounced.additionnalItems)
      ) !==
        JSON.stringify(
          flatOrderedIems(subscriptionDebouncedPrevious.additionnalItems)
        )
    ) {
      addServerAdditionalOrderableItems(subscriptionDebounced.additionnalItems);
    }
  }, [
    subscriptionDebounced,
    subscriptionDebouncedPrevious,
    updateServerStatus,
    addServerOrderableItems,
    addServerAdditionalOrderableItems,
    selectedMealsDebounced,
    selectedMealsDebouncedPrevious,
    selectedMeals,
  ]);

  //offs
  const updateSubscriptionOffs = useCallback(
    async (offs: string[]) => {
      if (subscription) {
        try {
          if (offsSource) {
            offsSource.cancel();
          }
          offsSource = axios.CancelToken.source();
          await axios.post(
            `/api/subscriptions/${subscription?.id}/offs`,
            {
              offs,
            },
            { cancelToken: offsSource.token }
          );
          offsSource = null;
        } catch (e) {}
      }
    },
    [subscription]
  );

  useEffect(() => {
    if (
      offsDebounced &&
      offsDebouncedPrevious &&
      JSON.stringify(offsDebouncedPrevious) !== JSON.stringify(offsDebounced)
    ) {
      updateSubscriptionOffs(offsDebounced);
    }
  }, [
    addServerOrderableItems,
    offsDebounced,
    offsDebouncedPrevious,
    updateSubscriptionOffs,
  ]);

  //selected-meals
  const updateSubscriptionMeals = useCallback(
    async (
      boxId: string,
      mealByDeliveryWeeks: Record<
        string,
        (Omit<OrderItem, `item`> & {
          item: Meal;
        })[]
      >
    ) => {
      if (subscription) {
        try {
          if (mealsSource) {
            mealsSource.cancel();
          }
          mealsSource = axios.CancelToken.source();
          await axios.post(
            `/api/subscriptions/${subscription?.id}/selected-meals`,
            {
              boxId,
              mealByDeliveryWeeks,
            },
            { cancelToken: mealsSource.token }
          );
          mealsSource = null;
        } catch (e) {}
      }
    },
    [subscription]
  );

  useEffect(() => {
    //selected meals
    if (
      subscription?.mealBox?.item?.id &&
      selectedMealsDebounced &&
      selectedMealsDebouncedPrevious &&
      Object.keys(selectedMealsDebounced)?.length > 0 &&
      JSON.stringify(selectedMealsDebounced) !==
        JSON.stringify(selectedMealsDebouncedPrevious)
    ) {
      updateSubscriptionMeals(
        subscription.mealBox.item.id,
        selectedMealsDebounced
      );
    }
    if (mealBoxPrevious?.item?.id && !subscription?.mealBox) {
      updateSubscriptionMeals(mealBoxPrevious.item.id, {});
    }
  }, [
    addServerOrderableItems,
    mealBoxPrevious?.item?.id,
    offsDebounced,
    offsDebouncedPrevious,
    selectedMealsDebounced,
    selectedMealsDebouncedPrevious,
    subscription?.mealBox,
    subscription?.mealBox?.item?.id,
    updateSubscriptionMeals,
    updateSubscriptionOffs,
  ]);

  return <>{children}</>;
};

export default SubscriptionProvider;
