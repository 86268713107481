import { format, isAfter, isSameDay, parseISO } from "date-fns";

import { Carrier, OrderableItemType } from "@efarmz/efarmz-domain-typescript";

import getNextDeliveryDate from "./getNextDeliveryDate";

const getNextCarrierDeliveryDate = (
  deliveryOptions: any,
  carrier: Carrier,
  orderableItemType: OrderableItemType = OrderableItemType.PRODUCT
): Date | null => {
  if (!carrier?.id || !deliveryOptions) {
    return null;
  }
  const carrierByIds: any = {};

  deliveryOptions.carriers?.forEach((item: any) => {
    carrierByIds[item.carrier_id] = item;
  });

  deliveryOptions.markers?.forEach((item: any) => {
    carrierByIds[item.carrier_id] = item;
  });

  const carrierFound = carrierByIds[carrier.id];

  if (!carrierFound) {
    return null;
  }

  const nexDeliveryDate = getNextDeliveryDate({ type: orderableItemType });

  const carrierNextDeliveryDates = carrierFound.availabilities
    ?.filter((innerAvailabilitiy: any) => {
      try {
        const innerStart = parseISO(innerAvailabilitiy.start);
        const innerEnd = parseISO(innerAvailabilitiy.end);

        const innerSlot = `${format(innerStart, `HH:mm`)} - ${format(
          innerEnd,
          `HH:mm`
        )}`;

        return (
          isAfter(innerStart, nexDeliveryDate) ||
          isSameDay(innerStart, nexDeliveryDate)
        );
      } catch (e) {
        return false;
      }
    })
    .sort((a: any, b: any) => {
      const startA = parseISO(a.start);
      const startB = parseISO(b.start);
      if (startA < startB) {
        return -1;
      } else if (startA == startB) {
        return 0;
      } else {
        return 1;
      }
    });

  /**
   * Fix an error where no carrier is found (disabled or such)
   * @see https://efarmz-ph.sentry.io/issues/5265272889/?project=4506519511367680&query=is%3Aunresolved+http.url%3Ahttps%3A%2F%2Fwww.efarmz.be%2Ffr%2Fpassword%2Freset%2F422caa7c62aa8f693eb085069278b8662e63d446610fea9524100df82dfb2a06&referrer=issue-stream&statsPeriod=14d&stream_index=1
   */
  const innerStart = Boolean(carrierNextDeliveryDates[0]?.start)
    ? parseISO(carrierNextDeliveryDates[0].start)
    : null;

  return innerStart;
};

export default getNextCarrierDeliveryDate;
