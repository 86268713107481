"use client";

import Script from "next/script";
import { useLocale } from "next-intl";

import useAuth from "@/hooks/useAuth";

type ActiveCampaignProviderProps = {
  children: React.ReactNode;
};

const NewsletterForm: any = {
  fr: `https://efarmz18185.activehosted.com/f/embed.php?id=11`,
  nl: `https://efarmz18185.activehosted.com/f/embed.php?id=13`,
};

const ActiveCampaignProvider = ({ children }: ActiveCampaignProviderProps) => {
  const locale = useLocale();
  const { user, status } = useAuth();
  return (
    <>
      {children}
      {process.env.NEXT_PUBLIC_ENV_PROFILE === `production` &&
        status !== `loading` &&
        !user && (
          <Script
            src={NewsletterForm[locale]}
            async
            defer
            strategy="lazyOnload"
          />
        )}
    </>
  );
};

export default ActiveCampaignProvider;
