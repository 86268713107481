"use client";

import React from "react";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";

import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";

import { DialogProvider } from "@efarmz/efarmz-react-commons/esm/hooks/useDialog";

import useSetFeatureFlagFromQueryString from "@/hooks/useSetFeatureFlagFromQueryString";

import ActiveCampaignProvider from "@/components/providers/ActiveCampaignProvider";
import AnalyticsProvider from "@/components/providers/AnalyticsProvider";
import CartProvider from "@/components/providers/CartProvider";
import DeliveryWindowProvider from "@/components/providers/DeliveryWindowProvider";
import SubscriptionProvider from "@/components/providers/SubscriptionProvider";

type ProvidersProps = {
  children: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps) => {
  useSetFeatureFlagFromQueryString();
  return (
    <>
      <IntercomProvider
        appId="ssvyam5u"
        apiBase="https://api-iam.intercom.io"
        autoBoot
        initializeDelay={1000}
        shouldInitialize={
          process?.env?.NEXT_PUBLIC_ENV_PROFILE === `production`
        }
      >
        <RecoilRoot>
          <SWRConfig
            value={{
              revalidateIfStale: false,
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
            }}
          >
            <AnalyticsProvider>
              <DialogProvider>
                <DeliveryWindowProvider>
                  <CartProvider>
                    <SubscriptionProvider>
                      <ActiveCampaignProvider>
                        {children}
                      </ActiveCampaignProvider>
                    </SubscriptionProvider>
                  </CartProvider>
                </DeliveryWindowProvider>
              </DialogProvider>
            </AnalyticsProvider>

            <ToastContainer
              toastClassName="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
              bodyClassName="p-4 font-sans"
              position="bottom-right"
              autoClose={3000}
              hideProgressBar
              closeButton={false}
            />
          </SWRConfig>
        </RecoilRoot>
      </IntercomProvider>
    </>
  );
};

export default Providers;
