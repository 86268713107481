import { addWeeks, getDay, getWeek, getYear } from "date-fns";

const getMealCurrentWeeks = (date?: Date) => {
  const currentDay = getDay(new Date());
  const calculatedDate =
    date || (currentDay >= 3 ? addWeeks(new Date(), 1) : new Date());

  return [
    calculatedDate,
    addWeeks(calculatedDate, 1),
    addWeeks(calculatedDate, 2),
  ].map(
    (date) =>
      `${getYear(date)}${getWeek(date).toLocaleString(`en-US`, {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`
  );
};

export default getMealCurrentWeeks;
