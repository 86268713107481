import { OrderItem } from "@efarmz/efarmz-domain-typescript";

import formatOrderableItem from "./formatOrderableItem";
import SegmentProduct from "./types/SegmentProduct";

export type SegmentProductItem = SegmentProduct & {
  quantity?: number;
};

const formatOrderItem = (orderItem: OrderItem): SegmentProductItem => {
  let segmentProductItem: SegmentProductItem = {};

  if (orderItem?.item) {
    segmentProductItem = formatOrderableItem(orderItem.item);
  }

  if (orderItem?.qty) {
    segmentProductItem.quantity = orderItem?.qty;
  }

  return segmentProductItem;
};

export default formatOrderItem;
